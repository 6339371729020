//Conclusive Colours:
$lightconcorange:             rgb(251,173,62);
$darkconcorange:             rgb(241,96,43);
$midconcorange:             rgb(246,143,53);

@import 'plugins';
@import 'qtip';
@import 'timeline';
@import 'circle';
@import 'notifs';
@import 'bootstrap-datepicker';

body {
  background-color: #f9f9f9 !important;
  position:relative;
  padding-top:75px;
  font-family:'Lato', 'Arial', sans-serif !important;
}
.bodywithpadding {
  padding-top: 150px;
}

//Calendar:
.tooltipevent {
width:240px;
height:125px;
border-radius: 6px;
padding:6px;
background:$lightconcorange;
position:absolute;
z-index:10001;
}

.barnoanimate {
  transition: none !important;
}

.dz-upload {
    display: block !important;
    background-color: red;
    height: 10px;
    width: 0%;
}

.bellblue {
  color:blue;
}

#sidebar-wrapper {
  width: 215px;
  background: #efefef;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  //z-index: 1000;
  transition: all 0.4s ease 0s;
}

.main-sidebar {
  background-color: #efefef;
  position: absolute;
  top: 0;
  width: 250px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-sidebar li {
  border-bottom: 1px solid #C0C0C0;
  //border-top: 1px solid #34364a;
}

.main-content {
  padding-top:5px;
  margin-left:-10px;
}

.sidebar-text {
  font-size:12px;
  padding-top:18px;
  padding-left:10px;
}

.popover {
  width:500px;
  height:325px;
  max-width: 100% !important;
  overflow-y: hidden;
}

.pageheaderrow {
  margin-top:3px;
  margin-bottom:3px;
}

.pageheader {
  margin-top:6px;
  margin-bottom:10px;
  font-size:28px;
}

.sidebaractive {
  color:black;
}

#notessearch {

}

//Activity stuff:
.activity-desc {
  //The whole text

}
.activity-user {
  //username
  font-weight:bold;
}
.activity-client {
  //username
  font-style: italic;
}
.activity-client a {
  color:black;
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
.activity-client a:hover {
  color: $darkconcorange;
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
.activity-time {
  //username
  font-style: italic;
  float:right;
}

.statuspopover {
  width:700px;
  max-width:700px;
}
.statuspopovercontent {
  padding:2px !important;
}

.navbar .navbar-fixed-top {
  margin-bottom:0 !important;
  padding-bottom:0 !important;
  border: 0;
}

.jumplink {
  display: block;
  position: relative;
  top: -45px;
  visibility: hidden;
}

.inputfloat {
  float:left;
}

.floatright {
  float:right;
}
.floatleft {
  float:left;
}

.small-table-text {
  font-size:12px;
}
.verticlealign td {
  vertical-align:middle;
}
.scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

.labelbigger {//for big pills for fieldnames on letters
  display:inline;
  font-weight: normal;
  margin-bottom:20px;
  padding-bottom:20px;
  margin:2px;
  font-size:18px;
  cursor:pointer;
}

.inbox-content-link:hover {
  text-decoration:none;
  color: #777777;
}

.credit-filter-box {
  font-family: inherit;
  margin-bottom: 1em;
  padding:10px;
  border-color: #dadada;
  border-radius: 6px;
  background: #efefef;
  box-shadow: none;
  font-size:15px;
}
.filter-picker {
  font-weight: bold;
  color: #39668e;
  text-decoration: none;
  border-bottom: 1px dotted #39668e;
  cursor: pointer;
}
.filter-picker:hover {
  font-weight: bold;
  color: #39668e;
  border-bottom: 3px dotted #39668e;
  cursor: pointer;
}

.cursorhand {
  cursor:pointer;
}
.hoverunderline:hover {
  cursor:pointer;
  text-decoration:underline;
}

.taskdesc {
  cursor:pointer;
}
.taskassigned {
  cursor:pointer;
}

.creditorlink:hover {
  cursor:pointer;
  display:block;
  text-decoration:underline;
}

.user-tab-pane {
  padding-top:5px;
}

.usercellhover:hover {
  text-decoration: underline;
}

footer {
  font-size: 12px;
  text-align: center;
  margin-top:20px;
  margin-bottom:20px;
}

.no-padding {
  padding:0 !important;
}
.no-padding-right {
  padding-right:0 !important;
}
.no-padding-left {
  padding-left:0 !important;
}

.font-weight-300 {
  font-weight: 300;
}

.email-to-search-box {
  display: none;
  position: absolute;
  width:500px;
  height:300px;
  background: white;
  border:1px solid $lightconcorange;
  z-index: 5;
  -webkit-box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    1px 1px 3px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         1px 1px 3px 0px rgba(50, 50, 50, 0.75);
  padding:10px;
  border-radius: 5px;
  overflow-x: auto;
}

.margin-5 {
  margin:5px;
}

.mt-1 {
  margin-top: 2%;
}

.mt-2 {
  margin-top: 2%;
}

.black-href {
  color: rgb(51, 51, 51)
}
