//Notif stuff::
/* CSS used here will be applied after bootstrap.css */

.notifs-notifications {
   min-width:420px !important;
  }

.notifs-notifications-wrapper {
   overflow:auto;
    max-height:250px;
  }

.notifs-menu-title {
   color:#ff7788;
   font-size:1.5rem;
    display:inline-block;
    }


.notifs-notification-heading, .notifs-notification-footer  {
	padding:2px 5px;
     }

.notifs-item-title {

 font-size:1.3rem;
 color:#000;

}

.notifs-notifications a.notifs-content {
 text-decoration:none;
 background:#f2f2f2;

 }

.notifs-link a:hover {
   text-decoration:none;
}
.notifs-link a:link {
   text-decoration:none;
}

.notifs-notification-item {
 padding-left:5px;
 padding-right:5px;
 padding-top:3px;
 padding-bottom:3px;
 margin:3px;
 background:#f2f2f2;
 border-radius:4px;
 }
